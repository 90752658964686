import React from 'react';

import Navbar2 from '../components/Navbar2';
import Welcome from '../components/Welcome';

const WelcomePage = () => {
  return (
    <div>
      <Navbar2/>
      <Welcome/>
    </div>
  )
}

export default WelcomePage;
