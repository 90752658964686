import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import axios from '../axios';
import Loader from '../utils/Loader';
import Error from '../utils/Error';
import { validateEmail } from '../utils/validation/validateUtils';

import '../styles/forgotPassword.scss';

function ForgotPassword(props) {
	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState('');
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	let history = useHistory();

	const handleRequestLink = (e) => {
		e.preventDefault();

		setIsError(false);
		setIsLoading(true);
		setErrors('');
		
		const data = {
			email,
		};

		const errorResult = validateEmail(data.email);

		if (!errorResult.isValid) {
			setIsLoading(false);
			setErrors(errorResult.errors);
			return;
		}

		const url = '/auth/forgot-password';

		axios
			.post(url, data)
			.then((res) => {
				setIsLoading(false);
				history.push('/signin');
			})
			.catch((err) => {
				setIsError(true);
				setErrorMsg(err.response.data.message);
				setIsLoading(false);
			});
	}

	return (
		<div id="forgotPwdPage">
			<div style={{ display: 'flex', flex: 1 }}>
				<div className='container'>
					<div className='pageWrapper'>
						<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
							<img src="/signup-bg.png" alt="login bg" />
						</div>
						<div className='forgotPwdForm'>
							<div style={{ fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom: 8 }}>
								Welcome !
							</div>
							<div style={{ fontSize: 14, color: "#616161" }}>
								Please enter the email address you’d like your password information sent to
							</div>
							<div className='forgotPwdInputBox'>
								<input
									className="forgotPwdInput"
									type="text"
									placeholder='Enter email address'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{
									errors.email ? (
										<div className='inputErrorMsg'>{errors.email}</div>
									) : ''
								}
							</div>
							<div className='forgotPwdActions'>
								<div className='requestLinkBtn' onClick={handleRequestLink}>Request Link</div>
								<Link className='backToLoginBtn' to='/signin'>Back to log in</Link>
							</div>
							{isLoading && <Loader />}
							{isError && <Error errorMsg={errorMsg} />}
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
				<img src="/login-bottom.png" alt="login bottom" />
			</div>
		</div>
	)
}

export default ForgotPassword;

