import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import CasesPage from '../views/CasesPage';
import Loader from '../utils/Loader';
import Error from '../utils/Error';
import axios from '../axios';
import { validateSignInInput } from '../utils/validation/validateUtils';

import '../styles/signIn.scss';

function SignIn() {
	const history = useHistory();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleSignIn = (e) => {
		e.preventDefault();

		setIsError(false);
		setIsLoading(true);
		setErrors({});

		const data = {
			email,
			password
		};

		const errorResult = validateSignInInput(data);

		if (!errorResult.isValid) {
			setIsLoading(false);
			setErrors(errorResult.errors);
			return;
		}

		axios
			.post('/auth/login', { ...data })
			.then((response) => {
				setIsLoading(false);
				localStorage.setItem('accessToken', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.accessToken);
				localStorage.setItem('name', response.data.user.name);
				localStorage.setItem('institution', response.data.user.institution);
				setIsAuthenticated(true);
				history.push('/cases');
			})
			.catch((error) => {
				setIsError(true);
				setErrorMsg(error.response.data.message);
				setIsLoading(false);
				setIsAuthenticated(false);
			})
	}

	return isAuthenticated ? (
		<CasesPage />
	) : (
		<div id="signInPage">
			<div style={{ display: 'flex', flex: 1 }}>
				<div className='container'>
					<div className='pageWrapper'>
						<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
							<img src="/signup-bg.png" alt="login bg" />
						</div>
						<div className='signInForm'>
							<div style={{ fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom: 8 }}>
								Welcome !
							</div>
							<div style={{ marginBottom: 20, fontSize: 14 }}>
								Not a member
								<Link to="/signup" style={{ fontWeight: '700', color: "#0C95BA", marginLeft: 10 }}>Sign up</Link>
							</div>
							<div className='signInInputBox'>
								<input
									className="signInInput"
									type="text"
									placeholder='Type your email address'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{
									errors.email ? (
										<div className='inputErrorMsg'>{errors.email}</div>
									) : ''
								}
							</div>
							<div className='signInInputBox'>
								<input
									className="signInInput"
									type="password"
									placeholder='Set a password'
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								{
									errors.password ? (
										<div className='inputErrorMsg'>{errors.password}</div>
									) : ''
								}
							</div>
							<div className='signInActions'>
								<div className='nextBtn' onClick={handleSignIn}>Next</div>
								<Link className='forgotPwdBtn' to='/forgot-password'>Forgot password?</Link>
							</div>
							{isLoading && <Loader />}
							{isError && <Error errorMsg={errorMsg} />}
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
				<img src="/login-bottom.png" alt="login bottom" />
			</div>
		</div>
	)
}

export default SignIn;
