import React from 'react';

import Chats from '../components/all-chats/Chats';
import Navbar from '../components/Navbar';

const ChatPage = () => {
	return (
		<div>
			<Navbar />
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ height: "70vh", minWidth: "90vw" }}>
					<Chats />
				</div>
			</div>
		</div>
	)
}

export default ChatPage;
