import React, { useState, useEffect } from 'react'
import { Paper, Link } from '@material-ui/core'
import { Grid, Button } from '@material-ui/core'
import styles from '../../styles/UserDetails.module.css'
import { PrimaryButton, PrimaryInput } from '../MaterialComponents'
import { useWindowDimensions } from '../../utils/windowUtils'
import axios from '../../axios'
import Loader from '../../utils/Loader'
import { useHistory } from 'react-router-dom'

function UserDetails() {
  let history= useHistory();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [institution, setInstitution] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios
      .get('/user')
      .then((res) => {
        setName(res.data.firstName + ' ' + res.data.lastName)
        setEmail(res.data.email)
        setInstitution(res.data.institution)
        setIsLoading(false)
        console.log(res)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }, [])
  const { width } = useWindowDimensions()
  return (
    <div style={{ marginTop: '5rem' }}>
      <Paper elevation={10} className={styles.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <div className={styles.icon}>
              <img src='user (1).png' className={styles.userIcon}></img>
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <h2 className={styles.label}>Name</h2>
                <h2 className={styles.label}>Email</h2>
                <h2 className={styles.label}>University</h2>
                <h2 className={styles.label}>Work</h2>
                <h2 className={styles.label}>Experience</h2>
              </Grid>

              <Grid item xs={8}>
                <h2 className={styles.info}>{isLoading ? <Loader /> : name}</h2>
                <h2 className={styles.info}>
                  {isLoading ? <Loader /> : email}
                </h2>
                <h2 className={styles.info}>
                  {isLoading ? <Loader /> : institution}
                </h2>
                <h2 className={styles.info}>Lorem</h2>
                <h2 className={styles.info}>Ipsum</h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={styles.btn} align='right'>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={styles.button}
            onClick={()=>{history.push("/profile")}}
          >
            Edit Profile
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default UserDetails
