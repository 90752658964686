import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '../utils/Loader';
import Error from '../utils/Error';
import axios from '../axios';
import { validateResetPassword } from '../utils/validation/validateUtils';

import '../styles/signIn.scss';

function ResetPassword(props) {
    const { token } = props;

	const history = useHistory();

	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [errors, setErrors] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleResetPwd = (e) => {
		e.preventDefault();

		setIsError(false);
		setIsLoading(true);
		setErrors('');

		const data = {
			password
		};

		const errorResult = validateResetPassword({ password, rePassword });

		if (!errorResult.isValid) {
			setIsLoading(false);
			setErrors(errorResult.errors);
			return;
		}

		axios
			.post('/auth/reset-password/' + token, { ...data })
			.then((response) => {
				setIsLoading(false);
				history.push('/signin');
			})
			.catch((error) => {
				setIsError(true);
				setErrorMsg(error.response.data.message);
				setIsLoading(false);
			})
	}

	return (
        <div id="signInPage">
			<div style={{ display: 'flex', flex: 1 }}>
				<div className='container'>
					<div className='pageWrapper'>
						<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
							<img src="/signup-bg.png" alt="login bg" />
						</div>
						<div className='signInForm'>
							<div style={{ fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom:16 }}>
								Reset Password
							</div>
							<div className='signInInputBox'>
								<input
									className="signInInput"
									type="text"
									placeholder='Password'
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								{
									errors.email ? (
										<div className='inputErrorMsg'>{errors.password}</div>
									) : ''
								}
							</div>
							<div className='signInInputBox'>
								<input
									className="signInInput"
									type="password"
									placeholder='Re-enter password'
									value={rePassword}
									onChange={(e) => setRePassword(e.target.value)}
								/>
								{
									errors.rePassword ? (
										<div className='inputErrorMsg'>{errors.rePassword}</div>
									) : ''
								}
							</div>
							<div className='signInActions'>
								<div className='nextBtn' onClick={handleResetPwd}>Reset</div>
							</div>
							{isLoading && <Loader />}
							{isError && <Error errorMsg={errorMsg} />}
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
				<img src="/login-bottom.png" alt="login bottom" />
			</div>
		</div>
    )
}

export default ResetPassword;
