import React from 'react';

import Navbar2 from '../components/Navbar2';
import SignUp from '../components/SignUp';

const SignUpPage = () => {
	return (
		<div>
			<Navbar2 />
			<SignUp />
		</div>
	)
}

export default SignUpPage;
