import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Modal } from 'react-responsive-modal';

import axios from '../../axios';

import '../../styles/CaseCard.css';

function CaseCard(props) {
	const { heading, difficulty, about, icon } = props;

	const [modal, setModal] = useState(false);
	const [resp, setResp] = useState({});

	let history = useHistory();

	const data = { caseNo: props.id };

	const onOpenModal = () => { setModal(true) };

	const onCloseModal = () => setModal(false);

	function clearcurrentPending() {
		const getData = {
			user_id: "",
			mode: 3,
			message1: "",
			message2: "z",
		};
		axios.post('/userconvo', JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				history.push('/cases')
			}).catch((err) => console.log(err));

	}
	const startSession = (e) => {
		axios
			.post('/check-cases', data)
			.then((res) => {
				setResp(res)
				console.log(res.data)
				if (res.data.hasPendingCases) {
					onOpenModal()
				}
				else {
					history.push({
						pathname: '/chatbox',
						state: { id: props.id, heading: props.heading, prob: props.problem, email: res.data.email, resume: false }
					})
				}
			})
			.catch((err) => {
				console.log(err.response)
			})
		e.preventDefault()
	}

	return (
		<>
			<Modal open={modal} onClose={onCloseModal} center>
				<p className="modal-text">
					Do you want to resume the case
				</p>
				<div className="button-div">
					<Button className="modal-button" onClick={() => {
						onCloseModal()
						console.log(resp)
						history.push({
							pathname: '/chatbox',
							state: { id: resp.data.caseNo, startT: resp.data.startT, email: resp.data.email, heading: resp.data.title, prob: resp.data.problem, resume: true }
						})
					}}>
						Ok
					</Button>
					<Button className="cancel-modal-button" onClick={() => {
						onCloseModal()
						clearcurrentPending()
					}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<div
				style={{
					background: "#FFFFFF",
					boxShadow: "0px 19px 250px -111px #FF63CE",
					borderRadius: 30,
					padding: 20,
					display: 'flex',
					margin: "0 15px",
					flexDirection: 'column'
				}}
			>
				<div style={{ fontSize: 18, fontWeight: 700, marginBottom: 20 }}>{heading}</div>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<div style={{ marginRight: 30 }}>
						<div style={{ fontSize: 12 }}>{difficulty}</div>
						<div style={{ fontSize: 12 }}>{about}</div>
						<div
							style={{
								marginTop: 15,
								backgroundImage: "linear-gradient(180deg, #0E5489 0%, #0D73A0 100%)",
								boxShadow: "0px 19px 250px -111px #FF63CE",
								borderRadius: 9,
								fontSize: 12,
								color: "#fff",
								fontWeight: 600,
								padding: "8px 12px",
								cursor: "pointer"
							}}
							onClick={startSession}
						>Start Now</div>
					</div>
					<div>
						<img src={icon} alt='' width={80} height={80} />
					</div>
				</div>
			</div>
		</>
	)
}

export default CaseCard;
