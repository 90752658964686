import React from 'react';

import Navbar2 from '../components/Navbar2';
import SignIn from '../components/SignIn';

const SignInPage = () => {
	return (
		<div>
			<Navbar2 />
			<SignIn />
		</div>
	)
}

export default SignInPage;
