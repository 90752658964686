import React from 'react';

import Navbar2 from '../components/Navbar2';
import ForgotPassword from '../components/ForgotPassword';

const ForgotPasswordPage = () => {
	return (
		<div>
			<Navbar2 />
			<ForgotPassword />
		</div>
	)
}

export default ForgotPasswordPage
