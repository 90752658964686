import React from 'react';
import { useParams } from 'react-router-dom';

import Navbar2 from '../components/Navbar2';
import Authenticate from '../components/Authenticate';

const AuthenticatePage = () => {
	const { token } = useParams();
	
	return (
		<div>
			<Navbar2 />
			<Authenticate token={token} />
		</div>
	)
}

export default AuthenticatePage
