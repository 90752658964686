import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import axios from '../axios';
import ProfilePage from '../views/ProfilePage';
import Loader from '../utils/Loader';
import Error from '../utils/Error';
import { validateSignUpInput } from '../utils/validation/validateUtils';

import '../styles/signup.scss';

function SignUp() {
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [password, setPassword] = useState('');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [errors, setErrors] = useState({})
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	let history = useHistory();

	const handleSignUp = (e) => {
		e.preventDefault()

		setIsError(false);
		setIsLoading(true);
		setErrors({});

		const userData = {
			firstName,
			lastName,
			email,
			password,
		};

		const errorResult = validateSignUpInput(userData);

		if (!errorResult.isValid) {
			setIsLoading(false);
			setErrors(errorResult.errors);
			return;
		}

		axios
			.post('/auth/signup', { ...userData })
			.then(() => {
				setIsLoading(false);
				history.push('/authenticate');
			})
			.catch((error) => {
				setIsError(true);
				setErrorMsg(error.response.data.message);
				setIsLoading(false);
				setIsAuthenticated(false);
			})
	}

	return isAuthenticated ? (
		<ProfilePage />
	) : (
		<div id="signUpPage">
			<div style={{ display: 'flex', flex: 1 }}>
				<div className='container'>
					<div className='pageWrapper'>
						<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
							<img src="/signup-bg.png" alt="login bg" />
						</div>
						<div className='signUpForm'>
							<div style={{ fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom: 8 }}>
								Sign Up !
							</div>
							<div style={{ marginBottom: 20, fontSize: 14 }}>
								Already have an account
								<Link to="/signin" style={{ fontWeight: '700', color: "#0C95BA", marginLeft: 10 }}>Log in</Link>
							</div>
							<div style={{ width: "75%" }}>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div className='signUpInputBox' style={{ display: 'flex', flex: 1, marginRight: 20 }}>
										<input
											className="signUpInput"
											type="text"
											placeholder='First Name'
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
										{
											errors.firstName ? (
												<div className='inputErrorMsg'>{errors.firstName}</div>
											) : ''
										}
									</div>
									<div className='signUpInputBox' style={{ display: 'flex', flex: 1, marginLeft: 20 }}>
										<input
											className="signUpInput"
											type="text"
											placeholder='Last Name'
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
										{
											errors.lastName ? (
												<div className='inputErrorMsg'>{errors.lastName}</div>
											) : ''
										}
									</div>
								</div>
								<div className='signUpInputBox'>
									<input
										className="signUpInput"
										type="text"
										placeholder='Email address'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									{
										errors.email ? (
											<div className='inputErrorMsg'>{errors.email}</div>
										) : ''
									}
								</div>
								<div className='signUpInputBox'>
									<input
										className="signUpInput"
										type="password"
										placeholder='Set a password'
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									{
										errors.password ? (
											<div className='inputErrorMsg'>{errors.password}</div>
										) : ''
									}
								</div>
							</div>
							<div className='signUpActions'>
								<div className='getStartedBtn' onClick={handleSignUp}>Get Started</div>
							</div>
							{isLoading && <Loader />}
							{isError && <Error errorMsg={errorMsg} />}
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
				<img src="/login-bottom.png" alt="login bottom" />
			</div>
		</div>
	)
}

export default SignUp
