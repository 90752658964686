import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CasesPage from '../views/CasesPage';
import Loader from '../utils/Loader';
import axios from '../axios';
import { validateSignInInput } from '../utils/validation/validateUtils';

import '../styles/welcome.scss';

function Welcome() {
	const history = useHistory();

	const [email, setEmail] = useState('');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const handleSignIn = (e) => {
		e.preventDefault()

		setIsLoading(true)
		setErrors({});

		const data = {
			email
		};

		const errorResult = validateSignInInput(data);

		if (!errorResult.isValid) {
			setIsLoading(false);
			setErrors(errorResult.errors);
			return;
		}

		axios
			.post('/auth/login', { ...data })
			.then((response) => {
				setIsLoading(false);
				localStorage.setItem('accessToken', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.accessToken);
				setIsAuthenticated(true);
				history.push('/authenticate');
			})
			.catch((error) => {
				setIsLoading(false);
				setIsAuthenticated(false);
			})
	}

	return isAuthenticated ? (
		<CasesPage />
	) : (
		<div id="welcomePage">
			<div style={{ display: 'flex', flex: 1 }}>
				<div className='container'>
					<div className='pageWrapper'>
						<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', paddingRight: 40 }}>
							<img src="/login-bg.png" alt="login bg" />
						</div>
						<div className='welcomeForm'>
							<div style={{ fontSize: 24, fontWeight: 600, lineHeight: 1.25, marginBottom: 24 }}>
								Welcome !
							</div>
							<div className='welcomeInputBox'>
								<input
									className="welcomeInput"
									type="text"
									placeholder='Type your email address'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<img src="edit-email.png" alt="edit email" />
							</div>
							<div className='welcomeActions'>
								<div className='nextBtn' onClick={handleSignIn}>Next</div>
							</div>
							{isLoading && <Loader />}
						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
				<img src="/login-bottom.png" alt="login bottom" />
			</div>
		</div>
	)
}

export default Welcome;
