import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';

import CaseCard from './CaseCard';
import axios from '../../axios';
import Loader from '../../utils/Loader';

import '../../styles/CaseCard.css';

function CaseSelector() {
	const [isLoading, setisLoading] = useState(true)

	const [cases, setCases] = useState({});
	const [userName, setUserName] = useState('');

	useEffect(() => {
		setUserName(localStorage.getItem('name'));
		axios
			.get('/convo-list')
			.then((res) => {
				setCases(res.data)
				setisLoading(false)
				console.log(res.data)
			})
			.catch((err) => {
				console.log(err.response)
			})
	}, [])

	return (
		<div>
			<Container maxWidth='lg'>
				{isLoading ? (
					<Loader />
				) : (
					<div style={{ padding: 50 }}>
						<div
							style={{
								padding: '70px 100px',
								borderRadius: 80,
								backgroundImage: "linear-gradient(296.02deg, #0C94B9 -7.24%, #0E437C 118.69%)",
								minHeight: "calc(100vh - 304px)",
								display: "flex",
								flexDirection: 'column',
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							{
								cases.length > 0 ? (
									<div style={{ fontSize: 24, textAlign: 'center', color: "#fff", marginBottom: 50 }}>
										Welcome to Casey {userName}!
									</div>
								) : (
									<div style={{ fontSize: 24, textAlign: 'center', color: "#fff", marginBottom: 50 }}>You’ve completed all 3 cases!</div>
								)
							}
							{
								cases.length > 0 ? (
									<div style={{ fontSize: 32, textAlign: 'center', color: "#fff", fontWeight: 700 }}>Start practicing!</div>
								) : ''
							}
							{
								cases.length > 0 ? (
									<div
										style={{
											padding: "0 60px",
											margin: "120px auto 60px",
											borderLeft: "4px solid #479BD2",
											borderRight: "4px solid #479BD2",
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center'
										}}
									>
										{
											cases.map((value) => (
												<CaseCard
													heading={value.name}
													difficulty={value.attributes.level}
													about={value.attributes.type}
													icon='steel.png'
													id={value.productId}
													problem={value.description}
												/>
											))
										}
									</div>
								) : ''
							}
							{
								cases.length > 0 ? (
									<div style={{ fontSize: 20, textAlign: 'center', color: "#fff", fontWeight: 100 }}>Note : You can attempt each case only one time</div>
								) : (
									<div style={{ textAlign: 'center' }}><a href="https://caseyinterview.com/case-interview-practice/" style={{ fontSize: 20, textAlign: 'center', color: "#fff", fontWeight: 100 }}>Practise more with Casey!</a></div>
								)
							}
						</div>
					</div>
				)}
			</Container>
		</div>
	)
}

export default CaseSelector;
