import React, { useState } from 'react';
import axios from '../axios'
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import '../styles/authenticate.scss';
import Loader from '../utils/Loader';

function Authenticate(props) {
	const { token } = props;

	const [showAuthenticateSuccess, setShowAuthenticateSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (token) {
			axios.post('/auth/authenticate/', { id: token }).then((response) => {
				setIsLoading(false);
				localStorage.setItem('accessToken', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.accessToken);
				localStorage.setItem('name', response.data.user.name);
				localStorage.setItem('institution', response.data.user.institution);
				setShowAuthenticateSuccess(true);
			}).catch((err) => {
				console.log(err);
				setIsLoading(false);
				setShowAuthenticateSuccess(false);
			})
		}
	}, []);

	const renderPostAuthenticate = () => {
		return (
			showAuthenticateSuccess ? (
				<div id="authenticatePage" className="authenticateSuccess">
					<div style={{ display: 'flex', flex: 1 }}>
						<div className='container'>
							<div className='pageWrapper'>
								<div style={{ display: 'flex', flex: 45, justifyContent: 'flex-end' }}>
									<img src="/authenticate-bg.png" alt="authenticate bg" />
								</div>
								<div style={{ display: 'flex', flex: 55, flexDirection: 'column' }}>
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<div style={{ fontSize: 24, fontWeight: 'bold' }}>Authentication successful</div>
										<div className='successTick'></div>
									</div>
									<div style={{ marginBottom: 28, fontSize: 14 }}>Enjoy practicing cases with casey</div>
									<Link className='authenticateNextBtn' to="/cases">Practice now</Link>
								</div>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
						<img src="/login-bottom.png" alt="login bottom" />
					</div>
				</div>
			) : (
				<div id="authenticatePage" className="authenticateFailure">
					<div style={{ display: 'flex', flex: 1 }}>
						<div className='container'>
							<div className='pageWrapper'>
								<div style={{ display: 'flex', flex: 45, justifyContent: 'flex-end' }}>
									<img src="/authenticate-bg.png" alt="authenticate bg" />
								</div>
								<div style={{ display: 'flex', flex: 55, flexDirection: 'column' }}>
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16 }}>
										<div style={{ fontSize: 24, fontWeight: 'bold' }}>Authentication failed</div>
										<div className='crossTick'></div>
									</div>
									<Link className='authenticateNextBtn' to="/signup">Try again</Link>
								</div>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
						<img src="/login-bottom.png" alt="login bottom" />
					</div>
				</div>
			)
		)
	}

	return (
		!token ? (
			<div id="authenticatePage">
				<div style={{ display: 'flex', flex: 1 }}>
					<div className='container'>
						<div className='pageWrapper'>
							<div style={{ display: 'flex', flex: 45, justifyContent: 'flex-end' }}>
								<img src="/authenticate-bg.png" alt="authenticate bg" />
							</div>
							<div style={{ display: 'flex', flex: 55, flexDirection: 'column' }}>
								<div style={{ width: "80%", borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16 }}>
									<div className='rightTick'></div>
									<div style={{ fontSize: 14, color: "#0C95BA", fontWeight: 'bold' }}>A verification link has been sent to your email.</div>
								</div>
								<div style={{ width: "80%", fontSize: 14, color: "#646464", marginTop: 16 }}>Please click on link that has just been sent to your email account to verify email and continue registration process.</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', alignSelf: 'flex-end' }}>
					<img src="/login-bottom.png" alt="login bottom" />
				</div>
			</div>
		) : (
			isLoading ? (
				<Loader />
			) : (
				renderPostAuthenticate()
			)
		)
	)
}

export default Authenticate