import React from 'react';
import { Container } from '@material-ui/core';

import { NavAppBar, NavToolBar } from './MaterialComponents';
import { Link } from 'react-router-dom';

const Navbar2 = () => {
	return (
		<NavAppBar style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
			<Container maxWidth='lg'>
				<NavToolBar>
					<Link
						to={'/'}
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							color: '#fff',
							textDecoration: 'none',
						}}
					>
						<span ><img style={{ height: 80 }} src="/casey-logo.png" alt="casey white logo" /></span>
					</Link>
				</NavToolBar>
			</Container>
		</NavAppBar>
	)
}

export default Navbar2
