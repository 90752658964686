import axios from 'axios'

axios.defaults.withCredentials = true

//axios.defaults.headers['Access-Control-Allow-Origin'] = 'http://localhost:300$
//axios.defaults.headers['Accept'] = '*/*';
//axios.defaults.headers['Accept-Language'] = 'en';
//axios.defaults.headers['Content-Language'] = 'en-US';
//////axios.defaults.headers['Content-Type'] = 'text/plain';

const instance = axios.create({
  baseURL: '/api',
  //baseURL: 'http://localhost:5000/api',
  withCredentials: true,
})

export default instance


