import React, { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { useHistory } from 'react-router-dom';
import { Container, Link } from '@material-ui/core';

import { NavAppBar, NavToolBar, NavDrawer } from './MaterialComponents';
import { useWindowDimensions } from '../utils/windowUtils';

import styles from '../styles/Navbar.module.css';

const Navbar = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const [userName, setUserName] = useState('');

	const { width } = useWindowDimensions();

	const toggleDrawer = (open) => {
		setIsDrawerOpen(open)
	}

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	let history = useHistory()

	const handleLogOut = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('name');
		localStorage.removeItem('institution');

		history.push('/signIn');
	}

	useEffect(() => {
		setUserName(localStorage.getItem('name'))
	}, []);

	return (
		<NavAppBar>
			<Container maxWidth='lg'>
				<NavToolBar>
					<Link
						href='/'
						style={{
							display: 'flex',
							alignItems: 'center',
							color: '#fff',
							textDecoration: 'none',
						}}
					>
						<span ><img style={{ height: "8vh", width: "10vw" }} src="casey-logo.png" alt='' /></span>
					</Link>

					{width >= 992 ? (
						<div
							className={styles.navLink}
							style={{
								display: 'flex',

								height: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{/* <Link href='/dashboard'>Dashboard</Link>
							<Link href='/cases'>Cases</Link>
							<Link href='/history'>History</Link> */}
							{/* <Link href='' style={{ alignSelf: 'center' }}>
								<img
									src='./user (1).png'
									alt=''
									style={{ height: '2rem', display: 'block', marginRight: '0' }}
									id='basic-button'
									aria-controls='basic-menu'
									aria-haspopup='true'
									aria-expanded={open ? 'true' : undefined}
									onClick={handleClick}
								></img>
							</Link> */}
							<div style={{ fontSize: 14, marginRight: 40 }}>{userName}</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									textAlign: 'center',
									cursor: 'pointer'
								}}
								onClick={handleLogOut}
							>
								<div><PowerSettingsNewOutlinedIcon /></div>
								<div style={{ fontSize: 12 }}>Logout</div>
							</div>
							<Menu
								id='basic-menu'
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								<MenuItem onClick={handleClose}>
									<img
										style={{
											height: '2rem',
											display: 'block',
											paddingTop: '0.5rem',
											marginRight: '1.5rem',
											paddingLeft: '1rem',
										}}
										src='./user (1).png'
										alt=''
									></img>
									<div style={{ paddingRight: '2rem' }}>
										<h4 style={{ marginBottom: '0' }}>Ayush Jindal</h4>

										<small
											style={{
												color: '#9e8f8e',
												margin: '0',
												fontSize: '0.75rem',
											}}
										>
											IIT Delhi
										</small>
									</div>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<img
										style={{
											height: '2rem',
											display: 'block',
											paddingTop: '0.5rem',
											marginRight: '1.5rem',
											paddingLeft: '1rem',
										}}
										src='./interview.png'
										alt=''
									></img>
									<div style={{ paddingRight: '2rem' }}>
										<h4 style={{ marginBottom: '0' }}>Interviews </h4>
										<small
											style={{
												color: '#9e8f8e',
												margin: '0',
												fontSize: '0.75rem',
											}}
										>
											tata, gigb
										</small>
									</div>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<img
										style={{
											height: '2rem',
											display: 'block',
											paddingTop: '0.5rem',
											marginRight: '1.5rem',
											paddingLeft: '1rem',
										}}
										src='./gear.png'
										alt=''
									></img>
									<div style={{ paddingRight: '2rem' }}>
										<h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
											Profile Settings
										</h4>
									</div>
								</MenuItem>
								<MenuItem onClick={handleLogOut}>
									<img
										style={{
											height: '2rem',
											display: 'block',
											paddingTop: '0.5rem',
											marginRight: '1.5rem',
											paddingLeft: '1rem',
										}}
										src='./log-out.png'
										alt=''
									></img>
									<div style={{ paddingRight: '2rem' }}>
										<h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
											Log Out
										</h4>
									</div>
								</MenuItem>
							</Menu>
						</div>
					) : (
						<div>
							<div style={{ marginRight: '1.2rem', display: 'inline-block' }}>
								<Link
									href=''
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<img
										src='./user (1).png'
										alt=''
										style={{
											height: '2rem',
											display: 'block',
											marginRight: '0',
										}}
										id='basic-button'
										aria-controls='basic-menu'
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
									></img>
								</Link>
								<Menu
									id='basic-menu'
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<MenuItem onClick={handleClose}>
										<img
											style={{
												height: '2rem',
												display: 'block',
												paddingTop: '0.5rem',
												marginRight: '1.5rem',
												paddingLeft: '1rem',
											}}
											src='./user (1).png'
											alt=''
										></img>
										<div style={{ paddingRight: '2rem' }}>
											<h4 style={{ marginBottom: '0' }}>Ayush Jindal</h4>

											<small
												style={{
													color: '#9e8f8e',
													margin: '0',
													fontSize: '0.75rem',
												}}
											>
												IIT Delhi
											</small>
										</div>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<img
											style={{
												height: '2rem',
												display: 'block',
												paddingTop: '0.5rem',
												marginRight: '1.5rem',
												paddingLeft: '1rem',
											}}
											src='./interview.png'
											alt=''
										></img>
										<div style={{ paddingRight: '2rem' }}>
											<h4 style={{ marginBottom: '0' }}>Interviews </h4>
											<small
												style={{
													color: '#9e8f8e',
													margin: '0',
													fontSize: '0.75rem',
												}}
											>
												tata, gigb
											</small>
										</div>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<img
											style={{
												height: '2rem',
												display: 'block',
												paddingTop: '0.5rem',
												marginRight: '1.5rem',
												paddingLeft: '1rem',
											}}
											src='./gear.png'
											alt=''
										></img>
										<div style={{ paddingRight: '2rem' }}>
											<h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
												Profile Settings
											</h4>
										</div>
									</MenuItem>
									<MenuItem onClick={handleLogOut}>
										<img
											style={{
												height: '2rem',
												display: 'block',
												paddingTop: '0.5rem',
												marginRight: '1.5rem',
												paddingLeft: '1rem',
											}}
											src='./log-out.png'
											alt=''
										></img>
										<div style={{ paddingRight: '2rem' }}>
											<h4 style={{ marginBottom: '0', marginTop: '0.5rem' }}>
												Log Out
											</h4>
										</div>
									</MenuItem>
								</Menu>
							</div>
							<MenuIcon fontSize='large' onClick={() => toggleDrawer(true)} />
							<NavDrawer
								anchor={'right'}
								open={isDrawerOpen}
								onClose={() => toggleDrawer(false)}
							>
								<div role='presentation' onClick={() => toggleDrawer(false)}>
									<div
										className={styles.navLink}
										style={{
											display: 'block',
										}}
									>
										<Link href=''>Dashboard</Link>
										<Link href=''>Cases</Link>
										<Link href=''>History</Link>
									</div>
								</div>
							</NavDrawer>
						</div>
					)}
				</NavToolBar>
			</Container>
		</NavAppBar>
	)
}

export default Navbar
