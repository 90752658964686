import React from 'react';
import { useParams } from 'react-router-dom';

import Navbar2 from '../components/Navbar2';
import ResetPassword from '../components/ResetPassword';

const ResetPasswordPage = () => {
	const { token } = useParams();

	return (
		<div>
			<Navbar2 />
			<ResetPassword token={token} />
		</div>
	)
}

export default ResetPasswordPage;
