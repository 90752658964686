import React from 'react';

import Navbar from '../components/Navbar';
import CaseSelector from '../components/cases/CaseSelector';

const CasesPage = ({ cards }) => {
	return (
		<div className='App'>
			<Navbar />
			<CaseSelector cards={cards} />
		</div>
	)
}

export default CasesPage
